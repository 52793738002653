<template>
  <div class="about">
    <div class="about_banner_wraper">
      <div class="about_banner_item">
        <div class="about_banner_item_title">实力团队，创新不止</div>
        <div class="about_banner_item_desc">10年专注政务软件研发</div>
        <div class="about_banner_item_detail">
          让政务单位真正节约增效的好帮手
        </div>
      </div>
    </div>
    <div class="about_intro">
      <div class="about_intro_title">公司简介</div>
      <div class="about_intro_desc">
        智慧政务高效办公、综合服务云平台解决方案服务商
      </div>
      <div class="about_intro_item">
        <div class="about_intro_li">厚德</div>
        <div class="about_intro_li">务实</div>
        <div class="about_intro_li">专注</div>
        <div class="about_intro_li">创新</div>
        <div class="about_intro_li">合作</div>
        <div class="about_intro_li">共赢</div>
      </div>
      <div class="about_intro_detail">
        厚实软件是一家专注于协同政务整体解决方案的软件服务提供商。<br />
        公司创立于2011年，秉承着“厚德、务实、专注、创新、合作、共赢”的创业理念，<br />
        深入电子政务行业智能化管理应用，致力成为“电子政务行业智能化管理服务知名品牌。”
      </div>
    </div>
    <div class="about_wraper_bg">
      <div class="about_experience">
        <div class="about_experience_item">
          <div class="about_experience_item_left">
            <img src="@/assets/images/about/se.png" alt="" />
            <div class="about_experience_item_title">个性化定制技术服务</div>
            <div class="about_experience_item_info">
              告别模版化系统，<br />
              为你量身订造更高性价比的系统
            </div>
          </div>
          <div class="about_experience_item_middle">
            <img src="@/assets/images/about/la.png" alt="" />
            <div class="about_experience_item_middle_title">服务模式</div>
            <img src="@/assets/images/about/ra.png" alt="" />
          </div>
          <div class="about_experience_item_right">
            <img src="@/assets/images/about/us.png" alt="" />
            <div class="about_experience_item_title">十年经验成熟团队</div>
            <div class="about_experience_item_info">
              成熟的政务工作、服务平台与技术服务三位一体<br />
              快速交付高质量的产品
            </div>
          </div>
        </div>
        <div class="about_experience_list">
          <div
            class="about_experience_list_li"
            v-for="(item, index) in listData"
            :key="index"
            @mouseenter="enter(item, index)"
            @mouseleave="leave"
          >
            <img :src="item.icon" alt="" />
            <div class="about_experience_list_li_wraper">
              <div class="about_experience_list_li_title">{{ item.date }}</div>
              <div
                class="about_experience_list_li_desc"
                v-html="item.desc"
              ></div>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div class="about_card" v-show="flag" :class="{ posi: pos }">
            <div class="about_card_item">
              <div class="about_card_item_info">
                <div class="about_card_item_date">{{ card.date }}</div>
                <div class="about_card_item_content">
                  {{ card.content }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="about_service">
      <div class="about_service_title">一站式运维服务</div>
      <div class="about_service_wraper">
        <div class="about_service_wraper_desc">服务优势</div>
        <div class="about_service_wraper_info">
          重点服务：专职工程师随同客户驻点服务<br />
          远程服务：驻点工程师远程服务<br />
          快速响应：节假日期间，1小时内响应问题，12小时内到现场，24小时内处理完毕<br />
          定期回访：定期回访了解系统使用情况
        </div>
      </div>
      <div class="about_service_item">
        <div class="about_service_item_li" v-for="item in list" :key="item.id">
          <img :src="item.img" alt="" />
          <div class="about_service_item_li_title">{{ item.title }}</div>
          <div class="about_service_item_li_info" v-html="item.info"></div>
        </div>
      </div>
    </div>
    <div class="about_auth_container">
      <div class="about_auth">
        <div class="about_auth_title">资质认证</div>
        <div class="about_auth_desc">资质认证 实力见证</div>
        <div class="about_auth_remark">（中国电信入库合作伙伴）</div>
        <div class="about_auth_item">
          <div
            class="about_auth_item_li"
            v-for="item in imgList"
            :key="item.id"
          >
            <img :src="item.img" alt="" />
            <div class="about_auth_item_li_title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_news">
      <div class="about_news_title">新闻动态</div>
      <div class="about_news_wraper">
        <div class="about_news_wraper_left">
          <div class="about_news_wraper_top">
            <div class="about_news_wraper_item">媒体关注</div>
            <div class="about_news_wraper_item">
              <img src="@/assets/images/about/arrow.png" alt="" />
            </div>
          </div>
          <div class="about_news_wraper_bottom">
            <img src="@/assets/images/about/left.png" alt="" />
            <div class="about_news_bottom_cantainer">
              <div class="about_news_bottom_cantainer_title">
                高明启动行政管理GPS监控"慢作为”
              </div>
              <div class="about_news_bottom_cantainer_date">
                2020-01-07 10:00
              </div>
              <div class="about_news_bottom_cantainer_info">
                今后，高明区行政“不作为、慢作为、乱作为”将受
                到“电子眼”的全程监控。
              </div>
            </div>
          </div>
        </div>
        <div class="about_news_wraper_right">
          <div class="about_news_wraper_top">
            <div class="about_news_wraper_item">厚实动态</div>
            <div class="about_news_wraper_item">
              <img src="@/assets/images/about/arrow.png" alt="" />
            </div>
          </div>
          <div class="about_news_wraper_bottom">
            <img src="@/assets/images/about/left.png" alt="" />
            <div class="about_news_bottom_cantainer">
              <div class="about_news_bottom_cantainer_title">
                官宣啦！厚实软件被评为国家高新技术企业
              </div>
              <div class="about_news_bottom_cantainer_date">
                2020-01-07 10:00
              </div>
              <div class="about_news_bottom_cantainer_info">
                高新技术企业指的是企业通过科学技术或者高科技
                发明在一些新的领域中有所发展,或是在原有的一些
                技术领域中改革创新的。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: '',
  components: {

  },
  props: {

  },
  data () {
    return {
      list: [
        {
          id: 1,
          img: require('@/assets/images/about/li1.png'),
          title: '平台运营',
          info: '收集用户反映的问题并及时调整系统，<br/>可灵活修改办公流程。<br/>提供政务新媒体运营服务。'
        },
        {
          id: 2,
          img: require('@/assets/images/about/li2.png'),
          title: '平台运营',
          info: '系统上线后工程师继续维护平台，<br/>确保平台持续稳定的运行。'
        },
        {
          id: 3,
          img: require('@/assets/images/about/li3.png'),
          title: '数据管理',
          info: '确保数据安全<br/>根据客户需求统计、分析数据'
        }
      ],
      imgList: [
        {
          id: 1,
          img: require('@/assets/images/about/icon1.png'),
          title: '知识产权贯标认证'
        },
        {
          id: 2,
          img: require('@/assets/images/about/icon2.png'),
          title: '智政注册商标'
        },
        {
          id: 3,
          img: require('@/assets/images/about/icon3.png'),
          title: '国家高新技术企业'
        },
        {
          id: 4,
          img: require('@/assets/images/about/icon4.png'),
          title: 'CMMI3认证'
        },
        {
          id: 5,
          img: require('@/assets/images/about/icon5.png'),
          title: '软件著作权'
        },
        {
          id: 6,
          img: require('@/assets/images/about/icon6.png'),
          title: '软件著作权'
        },
        {
          id: 7,
          img: require('@/assets/images/about/icon7.png'),
          title: '软件著作权'
        }
      ],
      listData: [
        {
          date: '2011',
          icon: require('@/assets/images/about/ic1.png'),
          desc: '东莞市厚实软件成立<br/>“智政”品牌建立'
        }, {
          date: '2012',
          icon: require('@/assets/images/about/ic2.png'),
          desc: '公司研发的政务办公自动化<br/>系统、工作日程管理软件在<br/>高明全面运行'
        }, {
          date: '2013',
          icon: require('@/assets/images/about/ic3.png'),
          desc: '公司研发的人大代表建议、<br/>行政导航管理、党代表工作室<br/>软件在佛山高明全面上线'
        }, {
          date: '2014',
          icon: require('@/assets/images/about/ic4.png'),
          desc: '公司研发的综合执法<br/>管理软件移动办公系<br/>统在佛山高明全面运行'
        }, {
          date: '2015',
          icon: require('@/assets/images/about/ic5.png'),
          desc: '公司研发的无纸化<br/>会议管理软件在佛<br/>山三水实行'
        }, {
          date: '2016',
          icon: require('@/assets/images/about/ic2.png'),
          desc: '公司研发的移动执法<br/>软件在佛山高明运行<br/>政务办公自动化系统<br/>在三水启用'
        }, {
          date: '2017',
          icon: require('@/assets/images/about/ic7.png'),
          desc: '公司研发的公共服务平台、<br/>固定资产投资项目库管理<br/>软件在高明全面上线'
        }, {
          date: '2018',
          icon: require('@/assets/images/about/ic8.png'),
          desc: '高明智慧饭堂、高明政务督查、<br/>智政无纸化办公自动化系统<br/>在三水和高明逐渐推开'
        }, {
          date: '2019',
          icon: require('@/assets/images/about/ic9.png'),
          desc: '政务办公自动化系统与互联网+<br/>政务服务系统在延安富县启用<br/>“高明通”微信服务公众平台上线<br/>绩效管理系统上线'
        }, {
          date: '2020',
          icon: require('@/assets/images/about/ic10.png'),
          desc: '教育招生系统<br/>公务员培训系统<br/>陕西吴起无纸化<br/>会议系统上线'
        }
      ],
      flag: false,
      direction: false,
      pos: false,
      card: {
        date: '',
        img: '',
        content: ''
      },
      cardImg: require('@/assets/images/about/card.png')
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    window.addEventListener('scroll', this.scroll)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.scroll)
    })
  },
  methods: {
    scroll () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      const targetHeight = $('.about_news_wraper').offset().top
      if (h > targetHeight - 500) {
        $('.about_news_wraper_left').addClass('animation')
        $('.about_news_wraper_right').addClass('animation')
      }
    },
    enter (item, index) {
      this.direction = index % 2 === 0
      this.pos = index % 2 !== 0
      this.flag = true
      this.card.date = item.date + '年'
      this.card.content = item.desc.replace(/[<br />]/g, '')
      this.card.img = this.cardImg
    },
    leave () {
      this.flag = false
      this.card.date = ''
      this.card.content = ''
      this.card.img = ''
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
